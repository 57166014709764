<template>
  <div class="es-login es-limited-access">
    <div class="es-limited-access__leftContainer">
      <div class="es-limited-access__left">
        <div class="es-limited-access__left__top-shade"></div>
        <div class="es-limited-access__left__bottom-uni"></div>
        <div class="es-limited-access__left__illustration"></div>
      </div>
    </div>
    <div class="es-login__right">
      <div class="es-login__container">
        <img class="es-login__logo" src="/logo_blue.svg" alt="logo eDocSign">
        <div v-show="!tokenGenerated">
          <h2 class="es-login__panel__title eui-u-text-blue-grey pl-0 ml-0">{{ $t('accessToken.renew.title') }}</h2>
          <h3 class="eui-u-text-blue-grey">
            {{ $t('accessToken.renew.explanation') }}
          </h3>
        </div>

        <div v-show="tokenGenerated">
          <h2 class="es-login__panel__title eui-u-text-blue-grey pl-0 ml-0">{{ $t('accessToken.renew.title.success') }}</h2>
          <p class="eui-u-text-blue-grey">
            {{ $t('accessToken.renew.explanation.success') }}
          </p>
        </div>

        <div v-show="!tokenGenerated">
          <form class="eui-u-flex eui-u-justify-end" @submit.prevent="renewToken">
              <EuiButton variant="raised" color="primary" @click.prevent="renewAccessToken()">{{ $t('accessToken.renew.button') }}</EuiButton>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AccessTokenRenewal',
  props: {
    stepUuid: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      tokenGenerated: false,
    };
  },
  methods: {
    ...mapActions({
      renewToken: 'ModuleEdocSign/auth/renewToken',
    }),
    renewAccessToken() {
      this.renewToken({ uuid: this.stepUuid }).then(() => {
        this.tokenGenerated = true;
      });
    },
  },
  mounted() {
  },
};
</script>
